import axios from "axios";
import { Category } from "./category";
import { Contact } from "./contact";
import { Event } from "./event";
import { Financial } from "./financial";
import { Tag } from "./tag";
import { Task } from "./task";
import {
    Attribute, BaseReq, CrudInfo, User
} from "./types";

const BASE_URL = process.env.NODE_ENV !== "production" ? process.env.REACT_APP_MONGO_PORT : process.env.REACT_APP_MONGO_PORT_PROD;

export interface GeoData {
	_id: string,
	name: string,
	layerName: string,
	description: string,
	documentType: "geoData",
	crudInfo: CrudInfo,
	status: string,
	color: string,
	tags: Array<Tag>,
	categories: Array<Category>,
	attributes: Array<Attribute>,
	tasks: Array<Task>,
	events: Array<Event>,
	contacts: Array<Contact>,
	financials: Array<Financial>
}

export interface GetAllGeoDataReq extends BaseReq {
	deleted?: boolean
}

export interface GetAllGeoDataRes extends Array<GeoData> { }

export async function getAllGeoData({
	token,
	deleted
}: GetAllGeoDataReq): Promise<GetAllGeoDataRes> {
	try {
		const { data } = await axios.get<GetAllGeoDataRes>(
			`${BASE_URL}/geodata${deleted ? '/deleted' : ''}`,
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface GetOneGeoDataReq extends BaseReq {
	_id: string
}

export async function getGeoData({
	token,
	_id
}: GetOneGeoDataReq): Promise<GeoData> {
	try {
		const { data } = await axios.get<GeoData>(
			`${BASE_URL}/geodata/${_id}`,
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface CreateGeoDataReq extends BaseReq {
	geoData: Omit<GeoData, "_id">
}

export async function createGeoData({
	token,
	geoData
}: CreateGeoDataReq): Promise<GeoData> {
	try {
		const { data } = await axios.post<GeoData>(
			`${BASE_URL}/geodata`,
			{ ...geoData },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface UpdateGeoDataReq extends BaseReq {
	geoData: Partial<GeoData>
}

export async function updateGeoData({
	token,
	geoData
}: UpdateGeoDataReq): Promise<GeoData> {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/geodata/${geoData._id}`,
			geoData,
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface DeleteGeoDataReq extends BaseReq {
	_id: string,
	deletedBy?: User,
	permanent?: boolean
}

export interface DeleteGeoDataRes extends GeoData { }

export async function deleteGeoData({
	token,
	_id,
	deletedBy,
	permanent
}: DeleteGeoDataReq) {
	try {
		const { data } = await axios.delete<DeleteGeoDataReq>(
			`${BASE_URL}/geodata/${_id}${permanent ? "?permanent=true" : ""}`,
			{ data: deletedBy, headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface BindTagReq extends BaseReq {
	_id: string,
	tags: Array<string>
}

export async function bindTagToGeoData({
	token,
	_id,
	tags
}: BindTagReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/geodata/${_id}`,
			{ tag: tags },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface BindCategoryReq extends BaseReq {
	_id: string,
	categories: Array<string>
}

export async function bindCategoryToGeoData({
	token,
	_id,
	categories
}: BindCategoryReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/geodata/${_id}`,
			{ category: categories },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface BindTaskReq extends BaseReq {
	_id: string,
	tasks: Array<string>
}

export async function bindTaskToGeoData({
	token,
	_id,
	tasks
}: BindTaskReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/geodata/${_id}`,
			{ task: tasks },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface BindEventReq extends BaseReq {
	_id: string,
	events: Array<string>
}

export async function bindEventToGeoData({
	token,
	_id,
	events
}: BindEventReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/geodata/${_id}`,
			{ event: events },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface BindContactReq extends BaseReq {
	_id: string,
	contacts: Array<string>
}

export async function bindContactToGeoData({
	token,
	_id,
	contacts
}: BindContactReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/geodata/${_id}`,
			{ contact: contacts },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface BindFinancialReq extends BaseReq {
	_id: string,
	financials: Array<string>
}

export async function bindFinancialToGeoData({
	token,
	_id,
	financials
}: BindFinancialReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/geodata/${_id}`,
			{ financial: financials },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface UnbindTagReq extends BaseReq {
	_id: string,
	tagId: string
}

export async function unbindTagFromGeoData({
	token,
	_id,
	tagId
}: UnbindTagReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/geodata/${_id}`,
			{ tagId: tagId },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface UnbindCategoryReq extends BaseReq {
	_id: string,
	categoryId: string
}

export async function unbindCategoryFromGeoData({
	token,
	_id,
	categoryId
}: UnbindCategoryReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/geodata/${_id}`,
			{ categoryId: categoryId },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface UnbindTaskReq extends BaseReq {
	_id: string,
	taskId: string
}

export async function unbindTaskFromGeoData({
	token,
	_id,
	taskId
}: UnbindTaskReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/geodata/${_id}`,
			{ taskId },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface UnbindEventReq extends BaseReq {
	_id: string,
	eventId: string
}

export async function unbindEventFromGeoData({
	token,
	_id,
	eventId
}: UnbindEventReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/geodata/${_id}`,
			{ eventId },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface UnbindContactReq extends BaseReq {
	_id: string,
	contactId: string
}

export async function unbindContactFromGeoData({
	token,
	_id,
	contactId
}: UnbindContactReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/geodata/${_id}`,
			{ contactId },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface UnbindFinancialReq extends BaseReq {
	_id: string,
	financialId: string
}

export async function unbindFinancialFromGeoData({
	token,
	_id,
	financialId
}: UnbindFinancialReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/geodata/${_id}`,
			{ financialId },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}