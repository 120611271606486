import {
	useMutation,
	useQuery,
	UseQueryOptions
} from "react-query";
import {
	bindCategoriesToNote, bindGeographyToNote,
	bindGeoProjectsToNote,
	bindGeoToolsToNote, bindTagsToNote, createNote,
	deleteNote,
	getAllNotes,
	GetAllNotesReq,
	GetAllNotesRes,
	getNote,
	GetOneNoteReq,
	Note, unbindCategoryFromNote, unbindGeographyFromNote,
	unbindGeoProjectFromNote,
	unbindGeoToolFromNote, unbindTagFromNote, updateNote
} from "../api/note";

import { queryClient } from "../providers";

export function useGetAllNotes(
	params: GetAllNotesReq,
	options?: UseQueryOptions<GetAllNotesRes, Error>
) {
	return useQuery<GetAllNotesRes, Error>(
		[params.deleted ? "Deleted Notes" : "Notes"],
		() => getAllNotes(params),
		options
	);
}

export function useGetNote(
	params: GetOneNoteReq,
	options?: UseQueryOptions<Note, Error>
) {
	return useQuery<Note, Error>(
		["Note", params._id],
		() => getNote(params),
		options
	);
}

export function useCreateNote() {
	return useMutation(createNote, {
		onSuccess: (data) => {
			queryClient.setQueryData<GetAllNotesRes>(["Notes"], (old) => {
				return (old as GetAllNotesRes).concat(data);
			});
		},
	});
}

export function useUpdateNote() {
	return useMutation(updateNote, {
		onSuccess: (data, variables) => {
			queryClient.setQueryData<Note>(
				["Note", variables.note._id],
				(old) => {
					return {
						...(data as Note),
						...variables
					};
				}
			);
			queryClient.refetchQueries(["Note", variables.note._id]); // TODO: figure out why this is not auto fetching
		},
	});
}

export function useDeleteNote() {
	return useMutation(deleteNote, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<Note>(["Note", _id], (old) => {
				return {
					...(old as Note),
					...data,
				};
			});
			queryClient.refetchQueries(["Deleted Notes"]);
		},
	});
}

export function useBindTagsToNote() {
	return useMutation(bindTagsToNote, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<Note>(["Note", _id], (old) => {
				return {
					...(old as Note),
					...data,
				};
			});
		},
	});
}

export function useBindCategoriesToNote() {
	return useMutation(bindCategoriesToNote, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<Note>(["Note", _id], (old) => {
				return {
					...(old as Note),
					...data,
				};
			});
		},
	});
}

export function useBindGeoProjectsToNote() {
	return useMutation(bindGeoProjectsToNote, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<Note>(["Note", _id], (old) => {
				return {
					...(old as Note),
					...data,
				};
			});
		},
	});
}

export function useBindGeographyToNote() {
	return useMutation(bindGeographyToNote, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<Note>(["Note", _id], (old) => {
				return {
					...(old as Note),
					...data,
				};
			});
		},
	});
}

export function useBindGeoToolsToNote() {
	return useMutation(bindGeoToolsToNote, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<Note>(["Note", _id], (old) => {
				return {
					...(old as Note),
					...data,
				};
			});
			queryClient.refetchQueries(["Note", _id]); // TODO: figure out why this is not auto fetching
		},
	});
}

export function useUnbindTagFromNote() {
	return useMutation(unbindTagFromNote, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<Note>(["Note", _id], (old) => {
				return {
					...(old as Note),
					...data,
				};
			});
		},
	});
}

export function useUnbindCategoryFromNote() {
	return useMutation(unbindCategoryFromNote, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<Note>(["Note", _id], (old) => {
				return {
					...(old as Note),
					...data,
				};
			});
		},
	});
}

export function useUnbindGeoProjectFromNote() {
	return useMutation(unbindGeoProjectFromNote, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<Note>(["Note", _id], (old) => {
				return {
					...(old as Note),
					...data,
				};
			});
		},
	});
}

export function useUnbindGeographyFromNote() {
	return useMutation(unbindGeographyFromNote, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<Note>(["Note", _id], (old) => {
				return {
					...(old as Note),
					...data,
				};
			});
		},
	});
}

export function useUnbindGeoToolFromNote() {
	return useMutation(unbindGeoToolFromNote, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<Note>(["Note", _id], (old) => {
				return {
					...(old as Note),
					...data,
				};
			});
		},
	});
}