import {
	useMutation,
	useQuery,
	UseQueryOptions
} from "react-query";
import {
	bindCategoriesToLink, bindGeographyToLink,
	bindGeoProjectsToLink,
	bindGeoToolsToLink, bindTagsToLink, createLink,
	deleteLink,
	getAllLinks,
	GetAllLinksReq,
	GetAllLinksRes,
	getLink,
	GetOneLinkReq,
	Link, unbindCategoryFromLink, unbindGeographyFromLink,
	unbindGeoProjectFromLink,
	unbindGeoToolFromLink, unbindTagFromLink, updateLink
} from "../api/link";

import { queryClient } from "../providers";

export function useGetAllLinks(
	params: GetAllLinksReq,
	options?: UseQueryOptions<GetAllLinksRes, Error>
) {
	return useQuery<GetAllLinksRes, Error>(
		[params.deleted ? "Deleted Links" : "Links"],
		() => getAllLinks(params),
		options
	);
}

export function useGetLink(
	params: GetOneLinkReq,
	options?: UseQueryOptions<Link, Error>
) {
	return useQuery<Link, Error>(
		["Link", params._id],
		() => getLink(params),
		options
	);
}

export function useCreateLink() {
	return useMutation(createLink, {
		onSuccess: (data) => {
			queryClient.setQueryData<GetAllLinksRes>(["Links"], (old) => {
				return (old as GetAllLinksRes).concat(data);
			});
		},
	});
}

export function useUpdateLink() {
	return useMutation(updateLink, {
		onSuccess: (data, variables) => {
			queryClient.setQueryData<Link>(
				["Link", variables.link._id],
				(old) => {
					return {
						...(data as Link),
						...variables
					};
				}
			);
			queryClient.refetchQueries(["Link", variables.link._id]); // TODO: figure out why this is not auto fetching
		},
	});
}

export function useDeleteLink() {
	return useMutation(deleteLink, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<Link>(["Link", _id], (old) => {
				return {
					...(old as Link),
					...data,
				};
			});
			queryClient.refetchQueries(["Deleted Links"]);
		},
	});
}

export function useBindTagsToLink() {
	return useMutation(bindTagsToLink, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<Link>(["Link", _id], (old) => {
				return {
					...(old as Link),
					...data,
				};
			});
		},
	});
}

export function useBindCategoriesToLink() {
	return useMutation(bindCategoriesToLink, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<Link>(["Link", _id], (old) => {
				return {
					...(old as Link),
					...data,
				};
			});
		},
	});
}

export function useBindGeoProjectsToLink() {
	return useMutation(bindGeoProjectsToLink, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<Link>(["Link", _id], (old) => {
				return {
					...(old as Link),
					...data,
				};
			});
		},
	});
}

export function useBindGeographyToLink() {
	return useMutation(bindGeographyToLink, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<Link>(["Link", _id], (old) => {
				return {
					...(old as Link),
					...data,
				};
			});
		},
	});
}

export function useBindGeoToolsToLink() {
	return useMutation(bindGeoToolsToLink, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<Link>(["Link", _id], (old) => {
				return {
					...(old as Link),
					...data,
				};
			});
			queryClient.refetchQueries(["Link", _id]); // TODO: figure out why this is not auto fetching
		},
	});
}

export function useUnbindTagFromLink() {
	return useMutation(unbindTagFromLink, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<Link>(["Link", _id], (old) => {
				return {
					...(old as Link),
					...data,
				};
			});
		},
	});
}

export function useUnbindCategoryFromLink() {
	return useMutation(unbindCategoryFromLink, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<Link>(["Link", _id], (old) => {
				return {
					...(old as Link),
					...data,
				};
			});
		},
	});
}

export function useUnbindGeoProjectFromLink() {
	return useMutation(unbindGeoProjectFromLink, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<Link>(["Link", _id], (old) => {
				return {
					...(old as Link),
					...data,
				};
			});
		},
	});
}

export function useUnbindGeographyFromLink() {
	return useMutation(unbindGeographyFromLink, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<Link>(["Link", _id], (old) => {
				return {
					...(old as Link),
					...data,
				};
			});
		},
	});
}

export function useUnbindGeoToolFromLink() {
	return useMutation(unbindGeoToolFromLink, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<Link>(["Link", _id], (old) => {
				return {
					...(old as Link),
					...data,
				};
			});
		},
	});
}