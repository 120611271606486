import { useAuth0 } from "@auth0/auth0-react";
import * as React from "react";
import { useLocation } from "react-router-dom";

export const useQueryParams = () => new URLSearchParams(useLocation().search);

export const useAuth0Token = () => {
	const [token, setToken] = React.useState("");
	const { getAccessTokenSilently } = useAuth0();
	React.useEffect(() => {
		(async () => {
			const tkn = await getAccessTokenSilently();
			setToken(tkn);
		})();
	}, [getAccessTokenSilently]);

	return token;
}