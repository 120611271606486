import axios from "axios";
import { Category } from "./category";
import { Contact } from "./contact";
import { Event } from "./event";
import { File } from "./file";
import { Financial } from "./financial";
import { Geography } from "./geography";
import { GeoTool } from "./geotool";
import { Link } from "./link";
import { Note } from "./note";
import { Tag } from "./tag";
import { Task } from "./task";
import {
    Attribute, BaseReq, CrudInfo, Geometry, User
} from "./types";

const BASE_URL = process.env.NODE_ENV !== "production" ? process.env.REACT_APP_MONGO_PORT : process.env.REACT_APP_MONGO_PORT_PROD;

export interface GeoProject {
	_id: string,
	name: string,
	description: string,
	documentType: "geoProject",
	crudInfo: CrudInfo,
	status: string,
	color: string,
	tags: Array<Tag>,
	categories: Array<Category>,
	attributes: Array<Attribute>,
	tasks: Array<Task>,
	events: Array<Event>,
	contacts: Array<Contact>,
	financials: Array<Financial>,
	location: Geometry,
	documents: Array<File | Link | Note>,
	geography: Array<Geography>,
	geoTools: Array<GeoTool>
}

export interface GetAllGeoProjectReq extends BaseReq {
	deleted?: boolean
}

export interface GetAllGeoProjectRes extends Array<GeoProject> { }

export async function getAllGeoProject({
	token,
	deleted
}: GetAllGeoProjectReq): Promise<GetAllGeoProjectRes> {
	try {
		const { data } = await axios.get<GetAllGeoProjectRes>(
			`${BASE_URL}/geoproject${deleted ? '/deleted' : ''}`,
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface GetOneGeoProjectReq extends BaseReq {
	_id: string
}

export async function getGeoProject({
	token,
	_id
}: GetOneGeoProjectReq): Promise<GeoProject> {
	try {
		const { data } = await axios.get<GeoProject>(
			`${BASE_URL}/geoproject/${_id}`,
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface CreateGeoProjectReq extends BaseReq {
	geoProject: Omit<GeoProject, "_id">
}

export async function createGeoProject({
	token,
	geoProject
}: CreateGeoProjectReq): Promise<GeoProject> {
	try {
		const { data } = await axios.post<GeoProject>(
			`${BASE_URL}/geoproject`,
			{ ...geoProject },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface UpdateGeoProjectReq extends BaseReq {
	geoProject: Partial<GeoProject>
}

export async function updateGeoProject({
	token,
	geoProject
}: UpdateGeoProjectReq): Promise<GeoProject> {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/geoproject/${geoProject._id}`,
			geoProject,
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface DeleteGeoProjectReq extends BaseReq {
	_id: string,
	deletedBy?: User,
	permanent?: boolean
}

export interface DeleteGeoProjectRes extends GeoProject { }

export async function deleteGeoProject({
	token,
	_id,
	deletedBy,
	permanent
}: DeleteGeoProjectReq) {
	try {
		const { data } = await axios.delete<DeleteGeoProjectReq>(
			`${BASE_URL}/geoproject/${_id}${permanent ? "?permanent=true" : ""}`,
			{ data: deletedBy, headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface BindDocumentReq extends BaseReq {
	_id: string,
	documents: Array<string>
}

export async function bindDocumentToGeoProject({
	token,
	_id,
	documents
}: BindDocumentReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/geoproject/${_id}`,
			{ documents },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface BindGeographyReq extends BaseReq {
	_id: string,
	geography: Array<string>
}

export async function bindGeographyToGeoProject({
	token,
	_id,
	geography
}: BindGeographyReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/geoproject/${_id}`,
			{ geography },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface BindGeoToolReq extends BaseReq {
	_id: string,
	geoTools: Array<string>
}

export async function bindGeoToolToGeoProject({
	token,
	_id,
	geoTools
}: BindGeoToolReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/geoproject/${_id}`,
			{ geoTools },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface BindTagReq extends BaseReq {
	_id: string,
	tags: Array<string>
}

export async function bindTagToGeoProject({
	token,
	_id,
	tags
}: BindTagReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/geoproject/${_id}`,
			{ tag: tags },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface BindCategoryReq extends BaseReq {
	_id: string,
	categories: Array<string>
}

export async function bindCategoryToGeoProject({
	token,
	_id,
	categories
}: BindCategoryReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/geoproject/${_id}`,
			{ category: categories },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface BindTaskReq extends BaseReq {
	_id: string,
	tasks: Array<string>
}

export async function bindTaskToGeoProject({
	token,
	_id,
	tasks
}: BindTaskReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/geoproject/${_id}`,
			{ task: tasks },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface BindEventReq extends BaseReq {
	_id: string,
	events: Array<string>
}

export async function bindEventToGeoProject({
	token,
	_id,
	events
}: BindEventReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/geoproject/${_id}`,
			{ event: events },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface BindContactReq extends BaseReq {
	_id: string,
	contacts: Array<string>
}

export async function bindContactToGeoProject({
	token,
	_id,
	contacts
}: BindContactReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/geoproject/${_id}`,
			{ contact: contacts },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface BindFinancialReq extends BaseReq {
	_id: string,
	financials: Array<string>
}

export async function bindFinancialToGeoProject({
	token,
	_id,
	financials
}: BindFinancialReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/geoproject/${_id}`,
			{ financial: financials },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface UnbindDocumentReq extends BaseReq {
	_id: string,
	documentId: string
}

export async function unbindDocumentFromGeoProject({
	token,
	_id,
	documentId
}: UnbindDocumentReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/geoproject/${_id}`,
			{ documentId },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface UnbindGeographyReq extends BaseReq {
	_id: string,
	geographyId: string
}

export async function unbindGeographyFromGeoProject({
	token,
	_id,
	geographyId
}: UnbindGeographyReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/geoproject/${_id}`,
			{ geographyId },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface UnbindGeoToolReq extends BaseReq {
	_id: string,
	geoToolId: string
}

export async function unbindGeoToolFromGeoProject({
	token,
	_id,
	geoToolId
}: UnbindGeoToolReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/geoproject/${_id}`,
			{ geoToolId },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface UnbindTagReq extends BaseReq {
	_id: string,
	tagId: string
}

export async function unbindTagFromGeoProject({
	token,
	_id,
	tagId
}: UnbindTagReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/geoproject/${_id}`,
			{ tagId: tagId },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface UnbindCategoryReq extends BaseReq {
	_id: string,
	categoryId: string
}

export async function unbindCategoryFromGeoProject({
	token,
	_id,
	categoryId
}: UnbindCategoryReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/geoproject/${_id}`,
			{ categoryId: categoryId },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface UnbindTaskReq extends BaseReq {
	_id: string,
	taskId: string
}

export async function unbindTaskFromGeoProject({
	token,
	_id,
	taskId
}: UnbindTaskReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/geoproject/${_id}`,
			{ taskId },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface UnbindEventReq extends BaseReq {
	_id: string,
	eventId: string
}

export async function unbindEventFromGeoProject({
	token,
	_id,
	eventId
}: UnbindEventReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/geoproject/${_id}`,
			{ eventId },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface UnbindContactReq extends BaseReq {
	_id: string,
	contactId: string
}

export async function unbindContactFromGeoProject({
	token,
	_id,
	contactId
}: UnbindContactReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/geoproject/${_id}`,
			{ contactId },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface UnbindFinancialReq extends BaseReq {
	_id: string,
	financialId: string
}

export async function unbindFinancialFromGeoProject({
	token,
	_id,
	financialId
}: UnbindFinancialReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/geoproject/${_id}`,
			{ financialId },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}