import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";
import { useNavigate } from "react-router-dom";

import config from "../lib/auth0/config";

const Auth0ProviderWithHistory: React.FunctionComponent<{ children: any }> = ({ children }) => {
	const navigate = useNavigate();

	const onRedirectCallback = (appState: any) => {
		navigate(appState?.returnTo || window.location.pathname);
	};

	return (
		<Auth0Provider
			domain={config.domain}
			clientId={config.clientID}
			redirectUri={window.location.origin}
			onRedirectCallback={onRedirectCallback}
			audience={config.audience}
		>
			{children}
		</Auth0Provider>
	);
};

export default Auth0ProviderWithHistory;
