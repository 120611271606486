import axios from "axios";
import {
    BaseReq, Document, User
} from "./types";

const BASE_URL = process.env.NODE_ENV !== "production" ? process.env.REACT_APP_MONGO_PORT : process.env.REACT_APP_MONGO_PORT_PROD;

export interface Note extends Document {
	_id: string,
	documentType: "Note"
}

export interface GetAllNotesReq extends BaseReq {
	deleted?: boolean
}

export interface GetAllNotesRes extends Array<Note> { }

export async function getAllNotes({
	token,
	deleted
}: GetAllNotesReq): Promise<GetAllNotesRes> {
	try {
		const { data } = await axios.get<GetAllNotesRes>(
			`${BASE_URL}/note${deleted ? '/deleted' : ''}`,
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface GetOneNoteReq extends BaseReq {
	_id: string
}

export async function getNote({
	token,
	_id
}: GetOneNoteReq): Promise<Note> {
	try {
		const { data } = await axios.get<Note>(
			`${BASE_URL}/note/${_id}`,
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface CreateNoteReq extends BaseReq {
	notes: Array<Omit<Note, "_id">>
}

export async function createNote({
	token,
	notes
}: CreateNoteReq): Promise<Array<Note>> {
	try {
		const { data } = await axios.post<Array<Note>>(
			`${BASE_URL}/note`,
			[...notes],
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface UpdateNoteReq extends BaseReq {
	note: Partial<Note>
}

export async function updateNote({
	token,
	note
}: UpdateNoteReq): Promise<Note> {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/note/${note._id}`,
			note,
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface DeleteNoteReq extends BaseReq {
	_id: string,
	deletedBy?: User,
	permanent?: boolean
}

export interface DeleteNoteRes extends Note { }

export async function deleteNote({
	token,
	_id,
	deletedBy,
	permanent
}: DeleteNoteReq) {
	try {
		const { data } = await axios.delete<DeleteNoteReq>(
			`${BASE_URL}/note/${_id}${permanent ? "?permanent=true" : ""}`,
			{ data: deletedBy, headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface BindTagReq extends BaseReq {
	_id: string,
	tags: Array<string>
}

export async function bindTagsToNote({
	token,
	_id,
	tags
}: BindTagReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/note/${_id}`,
			{ tags },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw (error);
	}
}

export interface BindCategoryReq extends BaseReq {
	_id: string,
	categories: Array<string>
}

export async function bindCategoriesToNote({
	token,
	_id,
	categories
}: BindCategoryReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/note/${_id}`,
			{ categories },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw (error);
	}
}

export interface BindGeoProjectReq extends BaseReq {
	_id: string,
	geoProjects: Array<string>
}

export async function bindGeoProjectsToNote({
	token,
	_id,
	geoProjects
}: BindGeoProjectReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/note/${_id}`,
			{ geoProjects },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw (error);
	}
}

export interface BindGeographyReq extends BaseReq {
	_id: string,
	geography: Array<string>
}

export async function bindGeographyToNote({
	token,
	_id,
	geography
}: BindGeographyReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/note/${_id}`,
			{ geography },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw (error);
	}
}

export interface BindGeoToolReq extends BaseReq {
	_id: string,
	geoTools: Array<string>
}

export async function bindGeoToolsToNote({
	token,
	_id,
	geoTools
}: BindGeoToolReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/note/${_id}`,
			{ geoTools },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw (error);
	}
}

export interface UnbindTagReq extends BaseReq {
	_id: string,
	tagId: string
}

export async function unbindTagFromNote({
	token,
	_id,
	tagId
}: UnbindTagReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/note/${_id}`,
			{ tagId },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface UnbindCategoryReq extends BaseReq {
	_id: string,
	categoryId: string
}

export async function unbindCategoryFromNote({
	token,
	_id,
	categoryId
}: UnbindCategoryReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/note/${_id}`,
			{ categoryId },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface UnbindGeoProjectReq extends BaseReq {
	_id: string,
	geoProjectId: string
}

export async function unbindGeoProjectFromNote({
	token,
	_id,
	geoProjectId
}: UnbindGeoProjectReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/note/${_id}`,
			{ geoProjectId },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw (error);
	}
}

export interface UnbindGeographyReq extends BaseReq {
	_id: string,
	geographyId: string
}

export async function unbindGeographyFromNote({
	token,
	_id,
	geographyId
}: UnbindGeographyReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/note/${_id}`,
			{ geographyId },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw (error);
	}
}

export interface UnbindGeoToolReq extends BaseReq {
	_id: string,
	geoToolId: string
}

export async function unbindGeoToolFromNote({
	token,
	_id,
	geoToolId
}: UnbindGeoToolReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/note/${_id}`,
			{ geoToolId },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw (error);
	}
}