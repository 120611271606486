import {
	useMutation,
	useQuery,
	UseQueryOptions
} from "react-query";
import {
	bindCategoryToGeoProject,
	bindContactToGeoProject,
	bindDocumentToGeoProject,
	bindEventToGeoProject,
	bindFinancialToGeoProject,
	bindGeographyToGeoProject,
	bindGeoToolToGeoProject,
	bindTagToGeoProject,
	bindTaskToGeoProject,
	createGeoProject,
	deleteGeoProject,
	GeoProject,
	getAllGeoProject,
	GetAllGeoProjectReq,
	GetAllGeoProjectRes,
	getGeoProject,
	GetOneGeoProjectReq,
	unbindCategoryFromGeoProject,
	unbindContactFromGeoProject,
	unbindDocumentFromGeoProject,
	unbindEventFromGeoProject,
	unbindFinancialFromGeoProject,
	unbindGeographyFromGeoProject,
	unbindGeoToolFromGeoProject,
	unbindTagFromGeoProject,
	unbindTaskFromGeoProject,
	updateGeoProject
} from "../api/geoproject";

import { queryClient } from "../providers";

export function useGetAllGeoProjects(
	params: GetAllGeoProjectReq,
	options?: UseQueryOptions<GetAllGeoProjectRes, Error>
) {
	return useQuery<GetAllGeoProjectRes, Error>(
		[params.deleted ? "Deleted GeoProjects" : "GeoProjects"],
		() => getAllGeoProject(params),
		options
	);
}

export function useGetGeoProject(
	params: GetOneGeoProjectReq,
	options?: UseQueryOptions<GeoProject, Error>
) {
	return useQuery<GeoProject, Error>(
		["GeoProject", params._id],
		() => getGeoProject(params),
		options
	);
}

export function useCreateGeoProject() {
	return useMutation(createGeoProject, {
		onSuccess: (data) => {
			queryClient.setQueryData<GetAllGeoProjectRes>(["GeoProjects"], (old) => {
				return (old as GetAllGeoProjectRes).concat(data);
			});
		},
	});
}

export function useUpdateGeoProject() {
	return useMutation(updateGeoProject, {
		onSuccess: (data, variables) => {
			queryClient.setQueryData<GeoProject>(
				["GeoProject", variables.geoProject._id],
				(old) => {
					return {
						...(data as GeoProject),
						...variables
					};
				}
			);
		},
	});
}

export function useDeleteGeoProject() {
	return useMutation(deleteGeoProject, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<GeoProject>(["GeoProject", _id], (old) => {
				return {
					...(old as GeoProject),
					...data,
				};
			});
			queryClient.refetchQueries(["Deleted GeoProjects"]);
		},
	});
}

export function useBindTagToGeoProject() {
	return useMutation(bindTagToGeoProject, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<GeoProject>(["GeoProject", _id], (old) => {
				return {
					...(old as GeoProject),
					...data,
				};
			});
		},
	});
}

export function useBindCategoryToGeoProject() {
	return useMutation(bindCategoryToGeoProject, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<GeoProject>(["GeoProject", _id], (old) => {
				return {
					...(old as GeoProject),
					...data,
				};
			});
		},
	});
}

export function useBindTaskToGeoProject() {
	return useMutation(bindTaskToGeoProject, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<GeoProject>(["GeoProject", _id], (old) => {
				return {
					...(old as GeoProject),
					...data,
				};
			});
		},
	});
}

export function useBindEventToGeoProject() {
	return useMutation(bindEventToGeoProject, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<GeoProject>(["GeoProject", _id], (old) => {
				return {
					...(old as GeoProject),
					...data,
				};
			});
		},
	});
}

export function useBindContactToGeoProject() {
	return useMutation(bindContactToGeoProject, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<GeoProject>(["GeoProject", _id], (old) => {
				return {
					...(old as GeoProject),
					...data,
				};
			});
		},
	});
}

export function useBindFinancialToGeoProject() {
	return useMutation(bindFinancialToGeoProject, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<GeoProject>(["GeoProject", _id], (old) => {
				return {
					...(old as GeoProject),
					...data,
				};
			});
		},
	});
}

export function useUnbindTagFromGeoProject() {
	return useMutation(unbindTagFromGeoProject, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<GeoProject>(["GeoProject", _id], (old) => {
				return {
					...(old as GeoProject),
					...data,
				};
			});
		},
	});
}

export function useUnbindCategoryFromGeoProject() {
	return useMutation(unbindCategoryFromGeoProject, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<GeoProject>(["GeoProject", _id], (old) => {
				return {
					...(old as GeoProject),
					...data,
				};
			});
		},
	});
}

export function useUnbindTaskFromGeoProject() {
	return useMutation(unbindTaskFromGeoProject, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<GeoProject>(["GeoProject", _id], (old) => {
				return {
					...(old as GeoProject),
					...data,
				};
			});
		},
	});
}

export function useUnbindEventFromGeoProject() {
	return useMutation(unbindEventFromGeoProject, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<GeoProject>(["GeoProject", _id], (old) => {
				return {
					...(old as GeoProject),
					...data,
				};
			});
		},
	});
}

export function useUnbindContactFromGeoProject() {
	return useMutation(unbindContactFromGeoProject, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<GeoProject>(["GeoProject", _id], (old) => {
				return {
					...(old as GeoProject),
					...data,
				};
			});
		},
	});
}

export function useUnbindFinancialFromGeoProject() {
	return useMutation(unbindFinancialFromGeoProject, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<GeoProject>(["GeoProject", _id], (old) => {
				return {
					...(old as GeoProject),
					...data,
				};
			});
		},
	});
}

export function useBindDocumentToGeoProject() {
	return useMutation(bindDocumentToGeoProject, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<GeoProject>(["GeoProject", _id], (old) => {
				return {
					...(old as GeoProject),
					documents: data.documents,
				};
			});
		},
	});
}

export function useBindGeographyToGeoProject() {
	return useMutation(bindGeographyToGeoProject, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<GeoProject>(["GeoProject", _id], (old) => {
				return {
					...(old as GeoProject),
					...data,
				};
			});
		},
	});
}

export function useBindGeoToolToGeoProject() {
	return useMutation(bindGeoToolToGeoProject, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<GeoProject>(["GeoProject", _id], (old) => {
				return {
					...(old as GeoProject),
					...data,
				};
			});
		},
	});
}

export function useUnbindDocumentFromGeoProject() {
	return useMutation(unbindDocumentFromGeoProject, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<GeoProject>(["GeoProject", _id], (old) => {
				return {
					...(old as GeoProject),
					...data,
				};
			});
		},
	});
}

export function useUnbindGeographyFromGeoProject() {
	return useMutation(unbindGeographyFromGeoProject, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<GeoProject>(["GeoProject", _id], (old) => {
				return {
					...(old as GeoProject),
					...data,
				};
			});
		},
	});
}

export function useUnbindGeoToolFromGeoProject() {
	return useMutation(unbindGeoToolFromGeoProject, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<GeoProject>(["GeoProject", _id], (old) => {
				return {
					...(old as GeoProject),
					...data,
				};
			});
		},
	});
}