import * as React from "react";

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter as Router } from "react-router-dom";
import Auth0ProviderWithHistory from "./context/auth0-provider-with-history";
import { UIStateProvider } from "./context/ui-context";

import theme from "./config/theme";

export const queryClient = new QueryClient();

type Props = {
  children: React.ReactNode;
};

export default function Providers({ children }: Props) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Router>
            <Auth0ProviderWithHistory>
              <UIStateProvider>
                {children}
                {process.env.NODE_ENV !== "production" && (
                  <ReactQueryDevtools initialIsOpen={false} />
                )}
              </UIStateProvider>
            </Auth0ProviderWithHistory>
          </Router>
        </SnackbarProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
}
