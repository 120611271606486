import axios from "axios";
import { Category } from "./category";
import { File } from "./file";
import { GeoProject } from "./geoproject";
import { Link } from "./link";
import { Note } from "./note";
import { Tag } from "./tag";
import {
	Attribute, BaseReq, CrudInfo, Geometry, User
} from "./types";

const BASE_URL = process.env.NODE_ENV !== "production" ? process.env.REACT_APP_MONGO_PORT : process.env.REACT_APP_MONGO_PORT_PROD;

export interface Geography {
	_id: string,
	name: string,
	description: string,
	documentType: "geography",
	crudInfo: CrudInfo,
	tags: Array<Tag>,
	categories: Array<Category>,
	attributes: Array<Attribute>,
	location: Geometry,
	lifeCycle: string,
	color: string,
	geoProjects: Array<GeoProject>,
	documents: Array<File | Link | Note>
}

export interface GetAllGeographyReq extends BaseReq {
	deleted?: boolean
}

export interface GetAllGeographyRes extends Array<Geography> { }

export async function getAllGeography({
	token,
	deleted
}: GetAllGeographyReq): Promise<GetAllGeographyRes> {
	try {
		const { data } = await axios.get<GetAllGeographyRes>(
			`${BASE_URL}/geography${deleted ? '/deleted' : ''}`,
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface GetOneGeographyReq extends BaseReq {
	_id: string
}

export async function getGeography({
	token,
	_id
}: GetOneGeographyReq): Promise<Geography> {
	try {
		const { data } = await axios.get<Geography>(
			`${BASE_URL}/geography/${_id}`,
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface GetByShapeGeographyReq extends BaseReq {
	type: string;
}

export async function getByShapeGeography({
	token,
	type,
}: GetByShapeGeographyReq) {
	try {
		const { data } = await axios.get<Array<Geography>>(
			`${BASE_URL}/geography/`,
			{
				headers: { Authorization: `Bearer ${token}` },
				params: { type },
			}
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface CreateGeographyReq extends BaseReq {
	geography: Omit<Geography, "_id">
}

export async function createGeography({
	token,
	geography
}: CreateGeographyReq): Promise<Geography> {
	try {
		const { data } = await axios.post<Geography>(
			`${BASE_URL}/geography`,
			{ ...geography },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface UpdateGeographyReq extends BaseReq {
	geography: Partial<Geography>
}

export async function updateGeography({
	token,
	geography
}: UpdateGeographyReq): Promise<Geography> {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/geography/${geography._id}`,
			geography,
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface DeleteGeographyReq extends BaseReq {
	_id: string,
	deletedBy?: User,
	permanent?: boolean
}

export interface DeleteGeographyRes extends Geography { }

export async function deleteGeography({
	token,
	_id,
	deletedBy,
	permanent
}: DeleteGeographyReq) {
	try {
		const { data } = await axios.delete<DeleteGeographyReq>(
			`${BASE_URL}/geography/${_id}${permanent ? "?permanent=true" : ""}`,
			{ data: deletedBy, headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface BindDocumentReq extends BaseReq {
	_id: string,
	documents: Array<string>
}

export async function bindDocumentToGeography({
	token,
	_id,
	documents
}: BindDocumentReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/geography/${_id}`,
			{ documents },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface BindGeoProjectReq extends BaseReq {
	_id: string,
	geoProjects: Array<string>
}

export async function bindGeoProjectToGeography({
	token,
	_id,
	geoProjects
}: BindGeoProjectReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/geography/${_id}`,
			{ geoProjects },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface BindTagReq extends BaseReq {
	_id: string,
	tags: Array<string>
}

export async function bindTagToGeography({
	token,
	_id,
	tags
}: BindTagReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/geography/${_id}`,
			{ tag: tags },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface BindCategoryReq extends BaseReq {
	_id: string,
	categories: Array<string>
}

export async function bindCategoryToGeography({
	token,
	_id,
	categories
}: BindCategoryReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/geography/${_id}`,
			{ category: categories },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface UnbindDocumentReq extends BaseReq {
	_id: string,
	documentId: string
}

export async function unbindDocumentFromGeography({
	token,
	_id,
	documentId
}: UnbindDocumentReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/geography/${_id}`,
			{ documentId },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface UnbindGeoProjectReq extends BaseReq {
	_id: string,
	geoProjectId: string
}

export async function unbindGeoProjectFromGeography({
	token,
	_id,
	geoProjectId
}: UnbindGeoProjectReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/geography/${_id}`,
			{ geoProjectId },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface UnbindTagReq extends BaseReq {
	_id: string,
	tagId: string
}

export async function unbindTagFromGeography({
	token,
	_id,
	tagId
}: UnbindTagReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/geography/${_id}`,
			{ tagId },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface UnbindCategoryReq extends BaseReq {
	_id: string,
	categoryId: string
}

export async function unbindCategoryFromGeography({
	token,
	_id,
	categoryId
}: UnbindCategoryReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/geography/${_id}`,
			{ categoryId },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}