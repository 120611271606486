import {
	useMutation,
	useQuery,
	UseQueryOptions
} from "react-query";
import {
	bindCategoryToGeoData,
	bindContactToGeoData,
	bindEventToGeoData,
	bindFinancialToGeoData,
	bindTagToGeoData,
	bindTaskToGeoData,
	createGeoData,
	deleteGeoData,
	GeoData,
	getAllGeoData,
	GetAllGeoDataReq,
	GetAllGeoDataRes,
	getGeoData,
	GetOneGeoDataReq,
	unbindCategoryFromGeoData,
	unbindContactFromGeoData,
	unbindEventFromGeoData,
	unbindFinancialFromGeoData,
	unbindTagFromGeoData,
	unbindTaskFromGeoData,
	updateGeoData
} from "../api/geodata";

import { queryClient } from "../providers";

export function useGetAllGeoData(
	params: GetAllGeoDataReq,
	options?: UseQueryOptions<GetAllGeoDataRes, Error>
) {
	return useQuery<GetAllGeoDataRes, Error>(
		[params.deleted ? "Deleted GeoData" : "GeoData"],
		() => getAllGeoData(params),
		options
	);
}

export function useGetGeoData(
	params: GetOneGeoDataReq,
	options?: UseQueryOptions<GeoData, Error>
) {
	return useQuery<GeoData, Error>(
		["GeoData", params._id],
		() => getGeoData(params),
		options
	);
}

export function useCreateGeoData() {
	return useMutation(createGeoData, {
		onSuccess: (data) => {
			queryClient.setQueryData<GetAllGeoDataRes>(["GeoData"], (old) => {
				return (old as GetAllGeoDataRes).concat(data);
			});
		},
	});
}

export function useUpdateGeoData() {
	return useMutation(updateGeoData, {
		onSuccess: (data, variables) => {
			queryClient.setQueryData<GeoData>(
				["GeoData", variables.geoData._id],
				(old) => {
					return {
						...(data as GeoData),
						...variables
					};
				}
			);
		},
	});
}

export function useDeleteGeoData() {
	return useMutation(deleteGeoData, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<GeoData>(["GeoData", _id], (old) => {
				return {
					...(old as GeoData),
					...data,
				};
			});
			queryClient.refetchQueries(["Deleted GeoData"]);
		},
	});
}

export function useBindTagToGeoData() {
	return useMutation(bindTagToGeoData, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<GeoData>(["GeoData", _id], (old) => {
				return {
					...(old as GeoData),
					...data,
				};
			});
		},
	});
}

export function useBindCategoryToGeoData() {
	return useMutation(bindCategoryToGeoData, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<GeoData>(["GeoData", _id], (old) => {
				return {
					...(old as GeoData),
					...data,
				};
			});
		},
	});
}

export function useBindTaskToGeoData() {
	return useMutation(bindTaskToGeoData, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<GeoData>(["GeoData", _id], (old) => {
				return {
					...(old as GeoData),
					...data,
				};
			});
		},
	});
}

export function useBindEventToGeoData() {
	return useMutation(bindEventToGeoData, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<GeoData>(["GeoData", _id], (old) => {
				return {
					...(old as GeoData),
					...data,
				};
			});
		},
	});
}

export function useBindContactToGeoData() {
	return useMutation(bindContactToGeoData, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<GeoData>(["GeoData", _id], (old) => {
				return {
					...(old as GeoData),
					...data,
				};
			});
		},
	});
}

export function useBindFinancialToGeoData() {
	return useMutation(bindFinancialToGeoData, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<GeoData>(["GeoData", _id], (old) => {
				return {
					...(old as GeoData),
					...data,
				};
			});
		},
	});
}

export function useUnbindTagFromGeoData() {
	return useMutation(unbindTagFromGeoData, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<GeoData>(["GeoData", _id], (old) => {
				return {
					...(old as GeoData),
					...data,
				};
			});
		},
	});
}

export function useUnbindCategoryFromGeoData() {
	return useMutation(unbindCategoryFromGeoData, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<GeoData>(["GeoData", _id], (old) => {
				return {
					...(old as GeoData),
					...data,
				};
			});
		},
	});
}

export function useUnbindTaskFromGeoData() {
	return useMutation(unbindTaskFromGeoData, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<GeoData>(["GeoData", _id], (old) => {
				return {
					...(old as GeoData),
					...data,
				};
			});
		},
	});
}

export function useUnbindEventFromGeoData() {
	return useMutation(unbindEventFromGeoData, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<GeoData>(["GeoData", _id], (old) => {
				return {
					...(old as GeoData),
					...data,
				};
			});
		},
	});
}

export function useUnbindContactFromGeoData() {
	return useMutation(unbindContactFromGeoData, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<GeoData>(["GeoData", _id], (old) => {
				return {
					...(old as GeoData),
					...data,
				};
			});
		},
	});
}

export function useUnbindFinancialFromGeoData() {
	return useMutation(unbindFinancialFromGeoData, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<GeoData>(["GeoData", _id], (old) => {
				return {
					...(old as GeoData),
					...data,
				};
			});
		},
	});
}