import { styled } from "@mui/material/styles";
import * as React from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { useUIState } from "../context/ui-context";
import { useAuth0Token } from "../hooks/utils";

import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ConstructionIcon from "@mui/icons-material/Construction";
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import DescriptionIcon from "@mui/icons-material/Description";
import GestureIcon from '@mui/icons-material/Gesture';
import LogoutIcon from '@mui/icons-material/Logout';
import MapIcon from "@mui/icons-material/Map";
import MenuIcon from "@mui/icons-material/Menu";
import ScatterPlotIcon from "@mui/icons-material/ScatterPlot";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from '@mui/material/Tooltip';
import Typography from "@mui/material/Typography";
import { Link as RouterLink, Outlet, useParams } from "react-router-dom";
import { useGetFile } from "../hooks/file";
import { useGetGeoData } from "../hooks/geodata";
import { useGetGeography } from "../hooks/geography";
import { useGetGeoProject } from "../hooks/geoproject";
import { useGetLink } from "../hooks/link";
import { useGetNote } from "../hooks/note";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
	open?: boolean;
}>(({ theme, open }) => ({
	flexGrow: 1,
	padding: theme.spacing(3),
	transition: theme.transitions.create("margin", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	marginLeft: `-${drawerWidth}px`,
	...(open && {
		transition: theme.transitions.create("margin", {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginLeft: 0,
	}),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	padding: theme.spacing(0, 1),
	...theme.mixins.toolbar,
	justifyContent: "flex-end",
}));

type Props = {
	children: React.ReactNode;
};

export default function PersistentDrawerLeft({ children }: Props) {
	const { logout, user } = useAuth0();
	const token = useAuth0Token();
	const { state, dispatch } = useUIState();

	const onLogout = () => logout({ returnTo: window.location.origin });

	const onToggleDrawer = () => {
		dispatch({ type: "TOGGLE_DRAWER", payload: !state.isDrawerOpen });
	};

	const params = useParams();
	const { data: geoProject } = useGetGeoProject({ token: token, _id: params._id ? params._id : "" }, { enabled: !!token && !!params._id });
	const { data: geography } = useGetGeography({ token: token, _id: params._id ? params._id : "" }, { enabled: !!token && !!params._id });
	const { data: geoData } = useGetGeoData({ token: token, _id: params._id ? params._id : "" }, { enabled: !!token && !!params._id });
	const { data: note } = useGetNote({ token: token, _id: params._id ? params._id : "" }, { enabled: !!token && !!params._id });
	const { data: link } = useGetLink({ token: token, _id: params._id ? params._id : "" }, { enabled: !!token && !!params._id });
	const { data: file } = useGetFile({ token: token, _id: params._id ? params._id : "" }, { enabled: !!token && !!params._id });

	return (
		<Box sx={{ display: "flex", height: "calc(100vh - 64px)" }}>
			<AppBar
				position="fixed"
				sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, background: "#043c5c" }}
			>
				<Toolbar sx={{
					alignItems: "center",
					justifyContent: "space-between"
				}}>
					<div style={{ alignItems: "center", display: "flex" }}>
						<IconButton
							color="inherit"
							aria-label="open drawer"
							onClick={onToggleDrawer}
							edge="start"
						>
							<MenuIcon />
						</IconButton>
						<img src="https://res.cloudinary.com/df0qmqpqs/branding/logo_long_svg.svg" alt="Land Scout" style={{ height: "3.6em", marginLeft: "5px" }} />
					</div>
					<div>
						{geoProject ? (<b style={{ fontSize: "1.8em" }}>{geoProject.name}</b>) : (geography ? (<b style={{ fontSize: "1.8em" }}>{geography.name}</b>) :
							(geoData ? (<b style={{ fontSize: "1.8em" }}>{geoData.name}</b>) : (note ? (<b style={{ fontSize: "1.8em" }}>{note.name}</b>) :
								(link ? (<b style={{ fontSize: "1.8em" }}>{link.name}</b>) : (file ? (<b style={{ fontSize: "1.8em" }}>{file.name}</b>) : "")))))}
					</div>
					<div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
						<Typography sx={{ marginRight: "5px" }}>
							{user?.name}
						</Typography>
						<Link component="button">
							<Avatar src={`${user?.picture}`} />
						</Link>
						<RouterLink to="/admin">
							<Tooltip title="Admin Page" enterDelay={2000} placement="bottom" componentsProps={{
								tooltip: { sx: { bgcolor: "#043c5c" } }
							}}>
								<IconButton>
									<AdminPanelSettingsIcon />
								</IconButton>
							</Tooltip>
						</RouterLink>
						<Tooltip title="Support" enterDelay={2000} placement="bottom" componentsProps={{
							tooltip: { sx: { bgcolor: "#043c5c" } }
						}}>
							<IconButton href="https://guide.landscout.com/app-help-broadband" target="_blank">
								<ContactSupportIcon />
							</IconButton>
						</Tooltip>
						<Link component="button" onClick={onLogout} color="textPrimary">
							<Tooltip title="Log Out" enterDelay={2000} placement="bottom" componentsProps={{
								tooltip: { sx: { bgcolor: "#043c5c" } }
							}}>
								<IconButton>
									<LogoutIcon />
								</IconButton>
							</Tooltip>
						</Link>
					</div>
				</Toolbar>
			</AppBar>
			<Drawer
				sx={{
					width: drawerWidth,
					flexShrink: 0,
					"& .MuiDrawer-paper": {
						width: drawerWidth,
						boxSizing: "border-box",
					}
				}}
				variant="persistent"
				anchor="left"
				open={state.isDrawerOpen}
				PaperProps={{
					sx: {
						backgroundColor: "#043c5c"
					}
				}}
			>
				<DrawerHeader></DrawerHeader>
				<Divider />
				<List>
					<ListItem button>
						<Link
							color="textPrimary"
							component={RouterLink}
							sx={{ display: "flex", width: "100%" }}
							to="/map"
							underline="none"
						>
							<ListItemIcon>
								<MapIcon />
							</ListItemIcon>
							<ListItemText primary={"Map"} />
						</Link>
					</ListItem>
					<ListItem button>
						<Link
							color="textPrimary"
							component={RouterLink}
							sx={{ display: "flex", width: "100%" }}
							to="/document"
							underline="none"
						>
							<ListItemIcon>
								<DescriptionIcon />
							</ListItemIcon>
							<ListItemText primary={"Documents"} />
						</Link>
					</ListItem>
					<ListItem button>
						<Link
							color="textPrimary"
							component={RouterLink}
							sx={{ display: "flex", width: "100%" }}
							to="/geoproject"
							underline="none"
						>
							<ListItemIcon>
								<ScatterPlotIcon />
							</ListItemIcon>
							<ListItemText primary={"GeoProjects"} />
						</Link>
					</ListItem>
					<ListItem button>
						<Link
							color="textPrimary"
							component={RouterLink}
							sx={{ display: "flex", width: "100%" }}
							to="/geography"
							underline="none"
						>
							<ListItemIcon>
								<GestureIcon />
							</ListItemIcon>
							<ListItemText primary={"Geography Data"} />
						</Link>
					</ListItem>
					<ListItem button>
						<Link
							color="textPrimary"
							component={RouterLink}
							sx={{ display: "flex", width: "100%" }}
							to="/geotool"
							underline="none"
						>
							<ListItemIcon>
								<ConstructionIcon />
							</ListItemIcon>
							<ListItemText primary={"GeoTool Data"} />
						</Link>
					</ListItem>
					<ListItem button>
						<Link
							color="textPrimary"
							component={RouterLink}
							sx={{ display: "flex", width: "100%" }}
							to="/geodata"
							underline="none"
						>
							<ListItemIcon>
								<TravelExploreIcon />
							</ListItemIcon>
							<ListItemText primary={"GeoData Library"} />
						</Link>
					</ListItem>
				</List>
			</Drawer>
			<Main open={state.isDrawerOpen}>
				<DrawerHeader />
				<Outlet />
				{children}
			</Main>
		</Box>
	);
}
