import axios from "axios";
import {
	BaseReq, Document, User
} from "./types";

const BASE_URL = process.env.NODE_ENV !== "production" ? process.env.REACT_APP_MONGO_PORT : process.env.REACT_APP_MONGO_PORT_PROD;

export interface Link extends Document {
	_id: string,
	url: string,
	documentType: "Link"
}

export interface GetAllLinksReq extends BaseReq {
	deleted?: boolean
}

export interface GetAllLinksRes extends Array<Link> { }

export async function getAllLinks({
	token,
	deleted
}: GetAllLinksReq): Promise<GetAllLinksRes> {
	try {
		const { data } = await axios.get<GetAllLinksRes>(
			`${BASE_URL}/link${deleted ? '/deleted' : ''}`,
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface GetOneLinkReq extends BaseReq {
	_id: string
}

export async function getLink({
	token,
	_id
}: GetOneLinkReq): Promise<Link> {
	try {
		const { data } = await axios.get<Link>(
			`${BASE_URL}/link/${_id}`,
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface CheckIframeReq extends BaseReq {
	_id: string
}

export async function checkIframe({
	token,
	_id
}: CheckIframeReq): Promise<boolean> {
	try {
		const { data } = await axios.get(
			`${BASE_URL}/link/iframe/${_id}`,
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface CreateLinkReq extends BaseReq {
	links: Array<Omit<Link, "_id">>
}

export async function createLink({
	token,
	links
}: CreateLinkReq): Promise<Array<Link>> {
	try {
		const { data } = await axios.post<Array<Link>>(
			`${BASE_URL}/link`,
			[...links],
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface UpdateLinkReq extends BaseReq {
	link: Partial<Link>
}

export async function updateLink({
	token,
	link
}: UpdateLinkReq): Promise<Link> {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/link/${link._id}`,
			link,
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface DeleteLinkReq extends BaseReq {
	_id: string,
	deletedBy?: User,
	permanent?: boolean
}

export interface DeleteLinkRes extends Link { }

export async function deleteLink({
	token,
	_id,
	deletedBy,
	permanent
}: DeleteLinkReq) {
	try {
		const { data } = await axios.delete<DeleteLinkReq>(
			`${BASE_URL}/link/${_id}${permanent ? "?permanent=true" : ""}`,
			{ data: deletedBy, headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface BindTagReq extends BaseReq {
	_id: string,
	tags: Array<string>
}

export async function bindTagsToLink({
	token,
	_id,
	tags
}: BindTagReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/link/${_id}`,
			{ tags },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw (error);
	}
}

export interface BindCategoryReq extends BaseReq {
	_id: string,
	categories: Array<string>
}

export async function bindCategoriesToLink({
	token,
	_id,
	categories
}: BindCategoryReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/link/${_id}`,
			{ categories },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw (error);
	}
}

export interface BindGeoProjectReq extends BaseReq {
	_id: string,
	geoProjects: Array<string>
}

export async function bindGeoProjectsToLink({
	token,
	_id,
	geoProjects
}: BindGeoProjectReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/link/${_id}`,
			{ geoProjects },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw (error);
	}
}

export interface BindGeographyReq extends BaseReq {
	_id: string,
	geography: Array<string>
}

export async function bindGeographyToLink({
	token,
	_id,
	geography
}: BindGeographyReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/link/${_id}`,
			{ geography },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw (error);
	}
}

export interface BindGeoToolReq extends BaseReq {
	_id: string,
	geoTools: Array<string>
}

export async function bindGeoToolsToLink({
	token,
	_id,
	geoTools
}: BindGeoToolReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/link/${_id}`,
			{ geoTools },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw (error);
	}
}

export interface UnbindTagReq extends BaseReq {
	_id: string,
	tagId: string
}

export async function unbindTagFromLink({
	token,
	_id,
	tagId
}: UnbindTagReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/link/${_id}`,
			{ tagId },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface UnbindCategoryReq extends BaseReq {
	_id: string,
	categoryId: string
}

export async function unbindCategoryFromLink({
	token,
	_id,
	categoryId
}: UnbindCategoryReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/link/${_id}`,
			{ categoryId },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface UnbindGeoProjectReq extends BaseReq {
	_id: string,
	geoProjectId: string
}

export async function unbindGeoProjectFromLink({
	token,
	_id,
	geoProjectId
}: UnbindGeoProjectReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/link/${_id}`,
			{ geoProjectId },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw (error);
	}
}

export interface UnbindGeographyReq extends BaseReq {
	_id: string,
	geographyId: string
}

export async function unbindGeographyFromLink({
	token,
	_id,
	geographyId
}: UnbindGeographyReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/link/${_id}`,
			{ geographyId },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw (error);
	}
}

export interface UnbindGeoToolReq extends BaseReq {
	_id: string,
	geoToolId: string
}

export async function unbindGeoToolFromLink({
	token,
	_id,
	geoToolId
}: UnbindGeoToolReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/link/${_id}`,
			{ geoToolId },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw (error);
	}
}