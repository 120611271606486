import axios from "axios";
import {
	BaseReq, Document, User
} from "./types";

const BASE_URL = process.env.NODE_ENV !== "production" ? process.env.REACT_APP_MONGO_PORT : process.env.REACT_APP_MONGO_PORT_PROD;

export interface File extends Document {
	_id: string,
	size: number,
	lastModified: number,
	type: string,
	extension: string,
	documentType: "File"
}

export interface GetAllFilesReq extends BaseReq {
	deleted?: boolean
}

export interface GetAllFilesRes extends Array<File> { }

export async function getAllFiles({
	token,
	deleted
}: GetAllFilesReq): Promise<GetAllFilesRes> {
	try {
		const { data } = await axios.get<GetAllFilesRes>(
			`${BASE_URL}/file${deleted ? '/deleted' : ''}`,
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface GetOneFileReq extends BaseReq {
	_id: string
}

export async function getFile({
	token,
	_id
}: GetOneFileReq): Promise<File> {
	try {
		const { data } = await axios.get<File>(
			`${BASE_URL}/file/${_id}`,
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface CreateFileReq extends BaseReq {
	files: Array<Omit<File, "_id">>
}

export async function createFile({
	token,
	files
}: CreateFileReq): Promise<Array<File>> {
	try {
		const { data } = await axios.post<Array<File>>(
			`${BASE_URL}/file`,
			[...files],
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface UpdateFileReq extends BaseReq {
	file: Partial<File>
}

export async function updateFile({
	token,
	file
}: UpdateFileReq): Promise<File> {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/file/${file._id}`,
			file,
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface DeleteFileReq extends BaseReq {
	_id: string,
	deletedBy?: User,
	permanent?: boolean
}

export interface DeleteFileRes extends File { }

export async function deleteFile({
	token,
	_id,
	deletedBy,
	permanent
}: DeleteFileReq) {
	try {
		const { data } = await axios.delete<DeleteFileReq>(
			`${BASE_URL}/file/${_id}${permanent ? "?permanent=true" : ""}`,
			{ data: deletedBy, headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface BindTagReq extends BaseReq {
	_id: string,
	tags: Array<string>
}

export async function bindTagsToFile({
	token,
	_id,
	tags
}: BindTagReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/file/${_id}`,
			{ tags },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw (error);
	}
}

export interface BindCategoryReq extends BaseReq {
	_id: string,
	categories: Array<string>
}

export async function bindCategoriesToFile({
	token,
	_id,
	categories
}: BindCategoryReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/file/${_id}`,
			{ categories },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw (error);
	}
}

export interface BindGeoProjectReq extends BaseReq {
	_id: string,
	geoProjects: Array<string>
}

export async function bindGeoProjectsToFile({
	token,
	_id,
	geoProjects
}: BindGeoProjectReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/file/${_id}`,
			{ geoProjects },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw (error);
	}
}

export interface BindGeographyReq extends BaseReq {
	_id: string,
	geography: Array<string>
}

export async function bindGeographyToFile({
	token,
	_id,
	geography
}: BindGeographyReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/file/${_id}`,
			{ geography },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw (error);
	}
}

export interface BindGeoToolReq extends BaseReq {
	_id: string,
	geoTools: Array<string>
}

export async function bindGeoToolsToFile({
	token,
	_id,
	geoTools
}: BindGeoToolReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/file/${_id}`,
			{ geoTools },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw (error);
	}
}

export interface UnbindTagReq extends BaseReq {
	_id: string,
	tagId: string
}

export async function unbindTagFromFile({
	token,
	_id,
	tagId
}: UnbindTagReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/file/${_id}`,
			{ tagId },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface UnbindCategoryReq extends BaseReq {
	_id: string,
	categoryId: string
}

export async function unbindCategoryFromFile({
	token,
	_id,
	categoryId
}: UnbindCategoryReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/file/${_id}`,
			{ categoryId },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export interface UnbindGeoProjectReq extends BaseReq {
	_id: string,
	geoProjectId: string
}

export async function unbindGeoProjectFromFile({
	token,
	_id,
	geoProjectId
}: UnbindGeoProjectReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/file/${_id}`,
			{ geoProjectId },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw (error);
	}
}

export interface UnbindGeographyReq extends BaseReq {
	_id: string,
	geographyId: string
}

export async function unbindGeographyFromFile({
	token,
	_id,
	geographyId
}: UnbindGeographyReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/file/${_id}`,
			{ geographyId },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw (error);
	}
}

export interface UnbindGeoToolReq extends BaseReq {
	_id: string,
	geoToolId: string
}

export async function unbindGeoToolFromFile({
	token,
	_id,
	geoToolId
}: UnbindGeoToolReq) {
	try {
		const { data } = await axios.put(
			`${BASE_URL}/file/${_id}`,
			{ geoToolId },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return data;
	} catch (error) {
		throw (error);
	}
}