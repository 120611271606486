import * as React from "react";

import { Route, Routes } from "react-router-dom";
import Loader from "./components/loader";
import PrivateRoute from "./components/private-route";

const Document = React.lazy(() => import("./ui-document/views"));
const FileDetails = React.lazy(() => import("./ui-document/views/details-file"));
const LinkDetails = React.lazy(() => import("./ui-document/views/details-link"));
const NoteDetails = React.lazy(() => import("./ui-document/views/details-note"));

const Admin = React.lazy(() => import("./ui-home/views/admin"));

const NotFound = React.lazy(() => import("./ui-home/views/not-found"));

const Home = React.lazy(() => import("./ui-home/views"));

const Map = React.lazy(() => import("./ui-map/views"));

const GeoProject = React.lazy(() => import("./ui-geoproject/views"));
const GeoProjectDetails = React.lazy(() => import("./ui-geoproject/views/details"));

const GeoData = React.lazy(() => import("./ui-geodata/views"));
const GeoDataDetails = React.lazy(() => import("./ui-geodata/views/details"));

const Geography = React.lazy(() => import("./ui-geography/views"));
const GeographyDetails = React.lazy(() => import("./ui-geography/views/details"));

const GeoTool = React.lazy(() => import("./ui-geotool/views"));
const GeoToolDetails = React.lazy(() => import("./ui-geotool/views/details"));

function App() {
	const MyPrivateRoute: any = PrivateRoute;
	return (

		<React.Suspense fallback={<Loader />}>
			<Routes>
				<Route path="/" element={
					<MyPrivateRoute>
						<Home />
					</MyPrivateRoute>
				} />
				<Route path="/document" element={
					<MyPrivateRoute>
						<Document />
					</MyPrivateRoute>
				} />
				<Route path="/file/:_id/*" element={
					<MyPrivateRoute>
						<FileDetails />
					</MyPrivateRoute>
				} />
				<Route path="/link/:_id/*" element={
					<MyPrivateRoute>
						<LinkDetails />
					</MyPrivateRoute>
				} />
				<Route path="/note/:_id/*" element={
					<MyPrivateRoute>
						<NoteDetails />
					</MyPrivateRoute>
				} />
				<Route path="/geoproject" element={
					<MyPrivateRoute>
						<GeoProject />
					</MyPrivateRoute>
				} />
				<Route path="/geoproject/:_id/*" element={
					<MyPrivateRoute>
						<GeoProjectDetails />
					</MyPrivateRoute>
				} />
				<Route path="/geodata" element={
					<MyPrivateRoute>
						<GeoData />
					</MyPrivateRoute>
				} />
				<Route path="/geodata/:_id/*" element={
					<MyPrivateRoute>
						<GeoDataDetails />
					</MyPrivateRoute>
				} />
				<Route path="/map" element={
					<MyPrivateRoute>
						<Map />
					</MyPrivateRoute>
				} />
				<Route path="/map/:_id" element={
					<MyPrivateRoute>
						<Map />
					</MyPrivateRoute>
				} />
				<Route path="/geography" element={
					<MyPrivateRoute>
						<Geography />
					</MyPrivateRoute>
				} />
				<Route path="/geography/:_id/*" element={
					<MyPrivateRoute>
						<GeographyDetails />
					</MyPrivateRoute>
				} />
				<Route path="/geotool" element={
					<MyPrivateRoute>
						<GeoTool />
					</MyPrivateRoute>
				} />
				<Route path="/geotool/:_id/*" element={
					<MyPrivateRoute>
						<GeoToolDetails />
					</MyPrivateRoute>
				} />
				<Route path="/admin" element={
					<MyPrivateRoute>
						<Admin />
					</MyPrivateRoute>
				} />
				<Route path="*" element={<NotFound />} />
			</Routes>
		</React.Suspense>

	);
}

export default App;
