import { createStyles, makeStyles } from "@mui/styles";

import loader from "../assets/loader.gif";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
      width: "100%",
      display: "relative",
    },
    loader: {
      position: "absolute",
      margin: "auto",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    },
  })
);

function Loader() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <img
        src={loader}
        alt="Loader"
        width="700"
        height="500"
        className={classes.loader}
      />
    </div>
  );
}

export default Loader;
