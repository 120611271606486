import {
	useMutation,
	useQuery,
	UseQueryOptions
} from "react-query";
import {
	bindCategoryToGeography,
	bindDocumentToGeography, bindGeoProjectToGeography, bindTagToGeography,
	createGeography,
	deleteGeography,
	Geography,
	getAllGeography,
	GetAllGeographyReq,
	GetAllGeographyRes,
	getByShapeGeography,
	GetByShapeGeographyReq,
	getGeography,
	GetOneGeographyReq,
	unbindCategoryFromGeography,
	unbindDocumentFromGeography, unbindGeoProjectFromGeography, unbindTagFromGeography,
	updateGeography
} from "../api/geography";

import { queryClient } from "../providers";

export function useGetAllGeography(
	params: GetAllGeographyReq,
	options?: UseQueryOptions<GetAllGeographyRes, Error>
) {
	return useQuery<GetAllGeographyRes, Error>(
		[params.deleted ? "Deleted Geography" : "Geography"],
		() => getAllGeography(params),
		options
	);
}

export function useGetGeography(
	params: GetOneGeographyReq,
	options?: UseQueryOptions<Geography, Error>
) {
	return useQuery<Geography, Error>(
		["Geography", params._id],
		() => getGeography(params),
		options
	);
}

export function useGetByShapeGeography(
	params: GetByShapeGeographyReq,
	options?: UseQueryOptions<Array<Geography>, Error>
) {
	return useQuery<Array<Geography>, Error>(
		["Geography", params.type],
		() => getByShapeGeography(params),
		options
	);
}

export function useCreateGeography() {
	return useMutation(createGeography, {
		onSuccess: (data) => {
			queryClient.setQueryData<GetAllGeographyRes>(["Geography"], (old) => {
				return (old as GetAllGeographyRes).concat(data);
			});
		},
	});
}

export function useUpdateGeography() {
	return useMutation(updateGeography, {
		onSuccess: (data, variables) => {
			queryClient.setQueryData<Geography>(
				["Geography", variables.geography._id],
				(old) => {
					return {
						...(data as Geography),
						...variables
					};
				}
			);
		},
	});
}

export function useDeleteGeography() {
	return useMutation(deleteGeography, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<Geography>(["Geography", _id], (old) => {
				return {
					...(old as Geography),
					...data,
				};
			});
			queryClient.refetchQueries(["Deleted Geography"]);
		},
	});
}

export function useBindGeoProjectToGeography() {
	return useMutation(bindGeoProjectToGeography, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<Geography>(["Geography", _id], (old) => {
				return {
					...(old as Geography),
					...data,
				};
			});
		},
	});
}

export function useBindDocumentToGeography() {
	return useMutation(bindDocumentToGeography, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<Geography>(["Geography", _id], (old) => {
				return {
					...(old as Geography),
					...data,
				};
			});
		},
	});
}

export function useBindTagToGeography() {
	return useMutation(bindTagToGeography, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<Geography>(["Geography", _id], (old) => {
				return {
					...(old as Geography),
					...data,
				};
			});
		},
	});
}

export function useBindCategoryToGeography() {
	return useMutation(bindCategoryToGeography, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<Geography>(["Geography", _id], (old) => {
				return {
					...(old as Geography),
					...data,
				};
			});
		},
	});
}

export function useUnbindGeoProjectFromGeography() {
	return useMutation(unbindGeoProjectFromGeography, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<Geography>(["Geography", _id], (old) => {
				return {
					...(old as Geography),
					...data,
				};
			});
		},
	});
}

export function useUnbindDocumentFromGeography() {
	return useMutation(unbindDocumentFromGeography, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<Geography>(["Geography", _id], (old) => {
				return {
					...(old as Geography),
					...data,
				};
			});
		},
	});
}

export function useUnbindTagFromGeography() {
	return useMutation(unbindTagFromGeography, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<Geography>(["Geography", _id], (old) => {
				return {
					...(old as Geography),
					...data,
				};
			});
		},
	});
}

export function useUnbindCategoryFromGeography() {
	return useMutation(unbindCategoryFromGeography, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<Geography>(["Geography", _id], (old) => {
				return {
					...(old as Geography),
					...data,
				};
			});
		},
	});
}