import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { ReactNode } from "react";
import { Navigate } from 'react-router-dom';
import Layout from "./layout";
import Loader from "./loader";

interface Props {
	children?: ReactNode
}

const PrivateRoute = ({ children }: Props) => {
	const { user } = useAuth0();

	return user ? <Layout>{children}</Layout> : <Navigate to="/test" />; // Create not logged in page or redirect to login
}

export default withAuthenticationRequired(PrivateRoute as any, {
	onRedirecting: () => <Loader />
});