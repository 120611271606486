import {
	useMutation,
	useQuery,
	UseQueryOptions
} from "react-query";
import {
	bindCategoriesToFile, bindGeographyToFile,
	bindGeoProjectsToFile,
	bindGeoToolsToFile, bindTagsToFile, createFile,
	deleteFile, File,
	getAllFiles,
	GetAllFilesReq,
	GetAllFilesRes,
	getFile,
	GetOneFileReq, unbindCategoryFromFile, unbindGeographyFromFile,
	unbindGeoProjectFromFile,
	unbindGeoToolFromFile, unbindTagFromFile, updateFile
} from "../api/file";

import { queryClient } from "../providers";

export function useGetAllFiles(
	params: GetAllFilesReq,
	options?: UseQueryOptions<GetAllFilesRes, Error>
) {
	return useQuery<GetAllFilesRes, Error>(
		[params.deleted ? "Deleted Files" : "Files"],
		() => getAllFiles(params),
		options
	);
}

export function useGetFile(
	params: GetOneFileReq,
	options?: UseQueryOptions<File, Error>
) {
	return useQuery<File, Error>(
		["Files", params._id],
		() => getFile(params),
		options
	);
}

export function useCreateFile() {
	return useMutation(createFile, {
		onSuccess: (data) => {
			queryClient.setQueryData<GetAllFilesRes>(["Files"], (old) => {
				return (old as GetAllFilesRes).concat(data);
			});
		},
	});
}

export function useUpdateFile() {
	return useMutation(updateFile, {
		onSuccess: (data, variables) => {
			queryClient.setQueryData<File>(
				["Files", variables.file._id],
				(old) => {
					return {
						...(data as File),
						...variables
					};
				}
			);
		}
	});
}

export function useDeleteFile() {
	return useMutation(deleteFile, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<File>(["Files", _id], (old) => {
				return {
					...(old as File),
					...data,
				};
			});
			queryClient.refetchQueries(["Deleted Files"]);
		},
	});
}

export function useBindTagsToFile() {
	return useMutation(bindTagsToFile, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<File>(["Files", _id], (old) => {
				return {
					...(old as File),
					...data,
				};
			});
		},
	});
}

export function useBindCategoriesToFile() {
	return useMutation(bindCategoriesToFile, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<File>(["Files", _id], (old) => {
				return {
					...(old as File),
					...data,
				};
			});
		},
	});
}

export function useBindGeoProjectsToFile() {
	return useMutation(bindGeoProjectsToFile, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<File>(["Files", _id], (old) => {
				return {
					...(old as File),
					...data,
				};
			});
		},
	});
}

export function useBindGeographyToFile() {
	return useMutation(bindGeographyToFile, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<File>(["Files", _id], (old) => {
				return {
					...(old as File),
					...data,
				};
			});
		},
	});
}

export function useBindGeoToolsToFile() {
	return useMutation(bindGeoToolsToFile, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<File>(["Files", _id], (old) => {
				return {
					...(old as File),
					...data,
				};
			});
			queryClient.refetchQueries(["Files", _id]); // TODO: figure out why this is not auto fetching
		},
	});
}

export function useUnbindTagFromFile() {
	return useMutation(unbindTagFromFile, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<File>(["Files", _id], (old) => {
				return {
					...(old as File),
					...data,
				};
			});
		},
	});
}

export function useUnbindCategoryFromFile() {
	return useMutation(unbindCategoryFromFile, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<File>(["Files", _id], (old) => {
				return {
					...(old as File),
					...data,
				};
			});
		},
	});
}

export function useUnbindGeoProjectFromFile() {
	return useMutation(unbindGeoProjectFromFile, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<File>(["Files", _id], (old) => {
				return {
					...(old as File),
					...data,
				};
			});
		},
	});
}

export function useUnbindGeographyFromFile() {
	return useMutation(unbindGeographyFromFile, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<File>(["Files", _id], (old) => {
				return {
					...(old as File),
					...data,
				};
			});
		},
	});
}

export function useUnbindGeoToolFromFile() {
	return useMutation(unbindGeoToolFromFile, {
		onSuccess: (data, { _id }) => {
			queryClient.setQueryData<File>(["Files", _id], (old) => {
				return {
					...(old as File),
					...data,
				};
			});
		},
	});
}